@font-face {
   font-family: 'HelveticaNeueCyr-Roman';
   src: url('../fonts/HelveticaNeueCyr-Roman.woff2') format('woff2')
}

@font-face {
   font-family: 'HelveticaNeueCyr-bold';
   src: url('../fonts/helveticaneuecyr-bold.woff2') format('woff2')
}

@font-face {
   font-family: 'open-sans-semibold';
   src: url('../fonts/open-sans-semibold.woff2') format('woff2')
}

@font-face {
   font-family: 'HelveticaNeueCyr-Medium';
   src: url('../fonts/HelveticaNeueCyr-Medium.woff2') format('woff2')
}

body {
   background-color: white;
   display: flex;
   flex-direction: column;
   gap: 40px;
}
.mt-2{
   margin-top: 2rem;
}
.mt-1{
   margin-top: 1rem;
}
.chose__screen {
   padding-bottom: 156px; 
   border-radius: 40px 40px 0 0;

   width: 375px;
   padding: 30px 25px;
   font-family: 'HelveticaNeueCyr-Roman', sans-serif;
   background: #fefefe;
   /* border-radius: 40px 40px 0 0; */
   margin: 0 auto;

   /* left: 50%;
   top: 50%;
   z-index: 1;
   position: absolute;
   transform: translate3d(-50%, -50%, 0); */
}

.screen__tetle {
   position: relative;
   display: flex;
   justify-content: center;
   font-family: 'Poppins', sans-serif;
   font-weight: 600;
   font-size: 16px;
   line-height: 24px;
   text-align: center;
   color: #060606;
   margin-bottom: 36px;
}

.screen__tetle img {
   position: absolute;
   left: 10%;
   top: 25%;
   height: 9px;
   width: 5.5px;
}

.screen__withdraw-profit {
   margin-top: 14px;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 5px;
}

.screen__withdraw-profit img {
   margin: 20px 0;
   width: 40px;
   height: 40px;
}

.screen__withdraw-profit-name {
   font-weight: 700;
   font-size: 16px;
   line-height: 20px;
   color: #000000;
   margin-bottom: 5px;
   font-family: 'HelveticaNeueCyr-bold';
}

.screen__withdraw-profit-text {
   font-weight: 400;
   font-size: 12px;
   line-height: 14px;
   color: rgba(0, 0, 0, 0.6);
}

.arrow-right img {
   height: 9px;
   width: 5.5px;
   transform: rotate(180deg);
}

.withdraw-profit_active {
   background: #FFFFFF;
   box-shadow: 0px 20px 64px rgba(0, 0, 0, 0.1);
   border-radius: 30px;

   position: relative;
   background: #1e1e1e;
   z-index: 0;
}

.withdraw-profit_active::before {
   content: '';
   background: #fff;
   border-radius: 28px;
   position: absolute;
   top: 2px;
   left: 2px;
   bottom: 2px;
   right: 2px;
   z-index: -1;
}

.btn-black {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 50px;
   color: #FFFFFF;
   font-size: 16px;
   line-height: 20px;
   background: #060606;
   border-radius: 50px;
   font-family: 'open-sans-semibold';
}

/* Conclusion to the map */
.block__chose {
   display: flex;
   flex-direction: column;
}

.block__chose p {
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;
   color: #000000;
}
.bank-choose{
    border-radius: 0.5rem !important;
    border: 2px solid #b5bfd9 !important;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: 0.15s ease;
    cursor: pointer;
}
.block__chose_input {
   display: flex;
   align-items: center;
   padding: 12px 26px 12px 14px;
   border: 1px solid #E6E6E6;
   border-radius: 20px;
   margin-top: 10px;
   margin-bottom: 20px;
   height: 59px;
}

.block__chose_input-img {
   width: 35px;
   text-align: center;
}

.delimiter {
   height: 22px;
   width: 2px;
   background-color: #F1F1F1;
   margin: 0 19px 0 16px;
}

.block__chose_input-btn {
   display: flex;
   justify-content: space-between;
   flex-grow: 1;
   cursor: pointer;
}

.block__chose_input-name {
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;
   color: #2D2D2D;
}

.block__chose-gradient {
   position: relative;
   border-radius: 20px;
   background: #1e1e1e;
   z-index: 0;
}

.block__chose-gradient::before {
   content: '';
   background: #fff;
   border-radius: 18px;
   position: absolute;
   top: 1px;
   left: 1px;
   bottom: 1px;
   right: 1px;
   z-index: -1;
}

#card__number,
#phone__number,
#amount__money,
#crypto__address {
   font-size: 16px;
   width: 100%;
   height: 100%;
}

.screen__text {
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;
   display: flex;
   align-items: center;

   color: #A3A3A3;
}



/* Conclusion to the map END */

/* Withdrawal to crypto wallet */
.block__chose_money {
   width: 100%;
   display: flex;
   justify-content: flex-end;
   align-items: flex-end;
   gap: 10px;
}

.block__chose_money-text {
   font-size: 14px;
   line-height: 17px;
   color: #000000;
}

.block__chose_money-money {
   font-size: 16px;
   line-height: 19px;
   color: #A3A3A3;
}

/* Withdrawal to crypto wallet END */

/* chose__connection */
.chose__connection {
   width: 328px;
   padding: 40px 25px;
   font-family: 'HelveticaNeueCyr-Romans', sans-serif;
   background: #fefefe;
   border-radius: 40px;
   box-shadow: 0px 20px 64px rgb(0 0 0 / 10%);
   display: flex;
   flex-direction: column;
   gap: 8px;
   margin: 0 auto;

   /* position: absolute;
   top: 50%;
   left: 50%;
   z-index: 1;
   transform: translate3d(-50%, -50%, 0); */
}

.block__chose_item {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 16px 18px 12px 20px;
   height: 60px;
   box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
   font-family: 'HelveticaNeueCyr-Medium';
   font-size: 18px;
   line-height: 24px;
   color: #060606;
   cursor: pointer;
}

.border-gradient-40 {
   position: relative;
   border-radius: 40px;
   background: #1e1e1e;
   z-index: 0;
}

.border-gradient-40::before {
   content: '';
   background: #fff;
   border-radius: 38px;
   position: absolute;
   top: 1.4px;
   left: 1.4px;
   bottom: 1.4px;
   right: 1px;
   z-index: -1;
}

/* chose__connection END */

/* info__window  */
.info__window {
   width: 328px;
   padding: 28px;
   font-family: 'HelveticaNeueCyr-Romans', sans-serif;
   background: #fefefe;
   border-radius: 40px;
   box-shadow: 0px 20px 64px rgb(0 0 0 / 10%);
   text-align: center;

   margin: 0 auto;

   /* position: absolute;
   top: 50%;
   left: 50%;
   z-index: 1;
   transform: translate3d(-50%, -50%, 0); */
}

.info__window-title {
   margin: 36px 0 15px;
   font-weight: 700;
   font-size: 22px;
   line-height: 27px;
   color: #060606;
}

.info__window-text {
   font-size: 14px;
   line-height: 17px;
   color: rgba(0, 0, 0, 0.6);
   margin-bottom: 65px;
}

.info__window-text span {
   font-weight: 700;
}

/* info__window END */
@media screen and (max-width: 400px) {
   .chose__screen {
      width: 320px;
      padding: 30px 8px;
   }

   .chose__connection {
      width: 320px;
      padding: 40px 8px;
   }

   .info__window {
      width: 300px;
   }
}



.swiper-slide {
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   height: auto !important;
}

/* transaction__screen */
.transaction__screen {
   width: 375px;
   min-height: 812px;
   padding: 31px 19px;
   font-family: 'HelveticaNeueCyr-Roman', sans-serif;
   background: #fefefe;
   /* box-shadow: 0px 20px 64px rgb(0 0 0 / 10%); */
   margin: 0 auto;
   /* overflow: hidden; */
   overflow-x: clip;
}

#mySwiper {
   overflow: visible;
}

#mySwiper .swiper-slide {
   display: flex;
   align-items: center;
   justify-content: center;
}

#sl1 {
   width: 65px !important;
}

#sl2 {
   width: 115px !important;
}

#sl3 {
   width: 106px !important;
}

#sl4 {
   width: 115px !important;
}

.transaction__screen_type {
   display: flex;
   font-family: 'HelveticaNeueCyr-Medium';
   letter-spacing: -0.012em;
   transition: .4s;
}

#mySwiper .active {
   background: #FFFFFF;
   box-shadow: 0px 20px 64px rgba(0, 0, 0, 0.1);
   border-radius: 40px;
}

.transaction__screen_type-item {
   padding: 11px 20px;
   white-space: nowrap;
   font-size: 14px;
   line-height: 17px;
   color: #000000;
}

.transaction__screen_tabl {
   display: flex;
   flex-direction: column;
   margin-top: 51px;
   gap: 27px;
}

.transaction__screen_tabl-row {
   display: flex;
   column-gap: 15px;
}

.transaction__screen_tabl-operation {
   flex: 1;
}

.transaction__screen_tabl-operation-what,
.transaction__screen_tabl-data-sum {
   display: flex;
   font-size: 16px;
   line-height: 19px;
   font-family: 'HelveticaNeueCyr-Medium';
}

.transaction__screen_tabl-operation-what img {
   margin-left: 15px;
   rotate: 180deg;
}

.transaction__screen_tabl-data {
   display: flex;
   flex-direction: column;
   align-items: flex-end;
}

.transaction__screen_tabl-data .green {
   color: #3AAF5C;
}

.transaction__screen_tabl-data .red {
   color: #F75050;
}

.transaction__screen_tabl-data .grey {
   color: #9D9D9D;
}

.transaction__screen_tabl-operation-where,
.transaction__screen_tabl-data-date {
   color: #9D9D9D;
   font-size: 14px;
   line-height: 17px;
   margin-top: 7.5px;
}

/* transaction__screen END */

/* transaction-data__window */

.transaction-data__window {
   width: 336px;
   min-height: 240px;
   padding: 28px 28px 26px 28px;
   font-family: 'HelveticaNeueCyr-Romans', sans-serif;
   background: #FFFFFF;
   border-radius: 30px;
   text-align: center;
   margin: 0 auto;
}

.transaction-data__window .btn-close {
   text-align: end;
}

.transaction-data__operation-name {
   font-size: 16px;
   line-height: 20px;
   font-family: 'HelveticaNeueCyr-Medium';
   background: linear-gradient(81.06deg, #FDC558 -103.99%, #FCA35D -69.07%, #FC9271 -35.18%, #FD889F -6.42%, #FD82C3 25.42%, #E177D1 59.31%, #AA65F6 92.17%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   margin-top: 20px;
   text-align: start;
}

#transaction_data_name {
   font-size: 16px;
   line-height: 20px;
   font-family: 'HelveticaNeueCyr-Medium';
   color: #000000;
}

#transaction_data_result {
   font-size: 14px;
   line-height: 17px;
}

.transaction-data__operation-tabl {
   display: flex;
   flex-direction: column;
   margin-top: 25px;
   gap: 15px;
}

.transaction-data__row {
   display: flex;
   justify-content: space-between;
   font-size: 16px;
   line-height: 19px;
   align-items: flex-end;
}

.transaction-data__row div:first-child {
   color: #9D9D9D;
}

.transaction-data__row div:last-child {
   color: #000000;
   font-family: 'HelveticaNeueCyr-Medium';
}

.transaction-data__window {
   background: #FFFFFF;
   box-shadow: 0px 20px 64px rgba(0, 0, 0, 0.1);
   border-radius: 30px;

   position: relative;
   background: #1e1e1e;
   z-index: 0;
}

.transaction-data__window::before {
   content: '';
   background: #fff;
   border-radius: 28px;
   position: absolute;
   top: 2px;
   left: 2px;
   bottom: 2px;
   right: 2px;
   z-index: -1;
}

.btn-close img {
   cursor: pointer;
}

/* transaction-data__window END */

.screen__tetle {
   position: relative;
   display: flex;
   justify-content: center;
   font-family: 'Poppins', sans-serif;
   font-weight: 600;
   font-size: 16px;
   line-height: 24px;
   text-align: center;
   color: #060606;
   margin-bottom: 41px;
}

.screen__tetle img {
   position: absolute;
   left: 7%;
   top: 25%;
   height: 9px;
   width: 5.5px;
}

.btn-gradient {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 50px;
   color: #FFFFFF;
   font-family: 'open-sans-semibold';
   font-size: 16px;
   line-height: 20px;
   background: #1e1e1e;
   border-radius: 50px;
}
.span-black {
   display: flex;
   align-items: center;
   align-self: center;
   margin: auto;
   justify-content: center;
   padding: 4px 10px 4px 10px !important; 
   color: #FFFFFF !important;
   font-family: 'open-sans-semibold';
   font-size: 10px;
   background: #a2a2a2 !important; 
   border-radius: 50px;
}
.span-gradient {
   display: flex;
   align-items: center;
   align-self: center;
   margin: auto;
   justify-content: center;
   padding: 5px 10px 5px 10px; 
   color: #FFFFFF;
   font-family: 'open-sans-semibold';
   font-size: 10px;
   background: #1e1e1e;
   border-radius: 50px;
}
/* update__window  */
.update__window {
   width: 328px;
   padding: 48px 35px 61px 35px;
   font-family: 'HelveticaNeueCyr-Romans', sans-serif;
   background: #fefefe;
   border-radius: 40px;
   box-shadow: 0px 20px 64px rgb(0 0 0 / 10%);
   text-align: center;
   margin: 0 auto;
}

.btn-close {
   background: transparent;
   width: 100%;
   text-align: left;
}

.update__window-title {
   margin: 29px 0 21px;
   font-weight: 700;
   font-size: 21px;
   line-height: 26px;
   color: #060606;
}

.update__window-text {
   align-items: center;
   text-align: center;
   font-size: 16px;
   color: #242424;
   line-height: 19px;
   margin-bottom: 36px;
   font-family: 'HelveticaNeueCyr-Roman';
}

.update__window .btn-gradient {
   height: 60px;
   font-family: 'Montserrat', sans-serif;
   font-size: 16px;
}

/* update__window END */

@media (pointer:none),
(pointer:coarse) {
   .transaction__screen_type-item {
      padding-top: 13px;
   }
}

@media screen and (max-width: 375px) {
   .transaction-data__window {
      width: 100%;
      padding: 20px;
   }

   .transaction__screen_tabl-operation {
      flex: 0 auto;
      min-width: 50vw;
   }
}

.whiteBlur{
   background: rgba(255, 255, 255, 0.22);
   border-radius: 16px;
   box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
   backdrop-filter: blur(5.3px);
   -webkit-backdrop-filter: blur(5.3px);
}

.loader{
    background: linear-gradient(to right, #000 0, #fff 20%, #000 30%);
    background-position: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    animation-fill-mode: forwards;
    -webkit-text-size-adjust: none;
    text-decoration: none;
   text-align: center;
    
}
.gradient-text{
   background: #F95A56;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-weight: bold;
}

@-moz-keyframes shine {
   0% {
   background-position: 0;
   }
   60% {
   background-position: 180px;
   }
   100% {
   background-position: 180px;
   }
}

@-webkit-keyframes shine {
   0% {
   background-position: 0;
   }
   60% {
   background-position: 180px;
   }
   100% {
   background-position: 180px;
   }
}
@-o-keyframes shine {
   0% {
   background-position: 0;
   }
   60% {
   background-position: 180px;
   }
   100% {
   background-position: 180px;
   }
}
@keyframes shine {
   0% {
   background-position: 0;
   }
   60% {
   background-position: 180px;
   }
   100% {
   background-position: 180px;
   }
}